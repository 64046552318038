body {
    font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif;
    margin: 0;
    background-color: #f0f2f5;
  }
  
  h1 {
    text-align: center;
    margin-top: 30px;
    color: #333;
  }
  
  .App {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .charts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  button {
    cursor: pointer;
  }
  