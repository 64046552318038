.loan-form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.form-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

label {
  flex: 1;
  margin-right: 20px;
}

input, select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.checkbox-label input {
  margin-right: 10px;
}

.interest-rate-change {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.interest-rate-change label {
  margin-right: 10px;
}

.add-button, .remove-button, .submit-button {
  padding: 10px 20px;
  margin-top: 10px;
}

.add-button, .remove-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.remove-button {
  background-color: #dc3545;
}

.submit-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  cursor: pointer;
}

.add-button:hover, .remove-button:hover, .submit-button:hover {
  opacity: 0.9;
}

.disclaimer {
  margin-top: 20px;
  font-size: 12px;
  color: #666;
}
