.alert {
  background-color: #ffdddd;
  color: #d8000c;
  padding: 10px;
  margin: 20px;
  border: 1px solid #d8000c;
}

.totals {
  margin: 20px;
  font-size: 16px;
}

.totals h2 {
  margin-bottom: 10px;
}

.alert {
  background-color: #ffdddd;
  color: #d8000c;
  padding: 10px;
  margin: 20px;
  border: 1px solid #d8000c;
}
