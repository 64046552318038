.chart-container {
    margin-bottom: 50px;
  }
  
  .tooltip {
    position: absolute;
    text-align: left;
    width: auto;
    padding: 8px;
    font-size: 12px;
    background: rgba(0,0,0,0.7);
    color: #fff;
    border: 0px;
    border-radius: 4px;
    pointer-events: none;
  }
  
  svg {
    border: 1px solid #ccc;
    background-color: #fff;
  }
  